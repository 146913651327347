<template>
    <div class="mobile-page-layout static-pages-layout">
        <div class="mobile-page-header">
            <div class="mobile-page-header-row">
                <div class="mobile-page-header-right">
                    <router-link :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <div class="mobile-page-header-icon">
                        <div class="mobile-page-header-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="header-svg-icon" viewBox="0 0 512 512"><path class="fa-primary" d="M448 95.1c0 6.812-2.891 13.28-7.938 17.85l-80 72C355.6 189.9 349.8 192 343.1 192c-3.312 0-6.618-.6875-9.759-2.062C325.6 186.1 320 177.5 320 168L319.1 128H160C107.1 128 64 171.1 64 224c0 17.69-14.33 32-32 32S0 241.7 0 224c0-88.22 71.78-160 160-160h160l-.0049-40c0-9.469 5.583-18.06 14.24-21.94c8.641-3.781 18.78-2.219 25.83 4.094l80 72C445.1 82.72 448 89.19 448 95.1z"/><path class="fa-secondary" d="M512 288c0 88.22-71.78 160-160 160H192l.0073 40c0 9.469-5.585 18.06-14.24 21.94C174.6 511.3 171.3 512 168 512c-5.812 0-11.57-2.125-16.07-6.156l-80-72C66.89 429.3 64 422.8 64 416s2.891-13.28 7.938-17.84l80-72C159 319.8 169.1 318.3 177.8 322.1C186.4 325.9 192 334.5 192 344L192 384H352c52.94 0 96-43.06 96-96c0-17.69 14.33-32 32-32S512 270.3 512 288z"/></svg>
                        </div>
                    </div>
                    <div class="mobile-page-header-text">نسخه‌ها</div>  

                </div>
            </div>
        </div>
        <div v-if="loading && !hide_static_market">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
        <slot v-if="!loading">
            <div class="versions-row" v-for="(item, index) in versions" :key="index">
                <h3>نسخه : {{ index }}</h3>
                <ul>
                    <li v-for="(item_value, index_value) in item" :key="index_value">
                        {{ item_value.content }}
                    </li>
                </ul>
                <i class="uil uil-redo icon"></i>
            </div>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'VersionsMobileView',
    components: {
    },
    data: function () {
        return {
            loading: true,
            versions: null,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loading = true;
            this.$helpers
            .makeRequest("GET", "/app/versions")
            .then((api_response) => {
                if (api_response.status == 200) {
                    this.versions = api_response.data.versions;
                    console.log(api_response);
                    this.loading = false;
                } 
            });
        },
    },
}
</script>
